import * as React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import illustration from "../images/illustration.png";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const IndexPage = ({ data }) => {
  return (
    <Layout>
      {/* header start */}
      <div className="overflow-hidden bg-yellow-400 px-6 pt-6 pb-28 sm:px-0">
        <div className="relative mx-auto max-w-lg">
          {/* nav start */}
          <span className="relative z-10 flex gap-4">
            <Link to="/" className="text-base font-semibold text-black">
              Sahil Khan
            </Link>
            <Link
              to="/about"
              className="text-base font-semibold text-black/50 hover:text-black hover:underline">
              About
            </Link>
            <Link
              to="/blog"
              className="text-base font-semibold text-black/50 hover:text-black hover:underline">
              Blog
            </Link>
          </span>
          {/* nav end */}
          <img
            src={illustration}
            alt="illustration of Sahil Khan"
            className="absolute top-0 -right-36 w-96 sm:-top-6 sm:-right-52"
          />
        </div>
      </div>
      {/* header end */}

      {/* body start */}
      <div className="mx-auto max-w-lg overflow-hidden px-6 pt-12 sm:overflow-visible sm:px-0">
        <main className="prose prose-invert prose-a:text-gray-400 hover:prose-a:text-gray-200 prose-hr:border-gray-800">
          <p className="text-2xl font-bold text-neutral-100">
            Hmm... Should you be here? Anyway, check out my lastest blog posts
            below. Or say hi on{" "}
            <a className="font-bold" href="https://twitter.com/sahilk">
              Twitter
            </a>
            .
          </p>

          <hr />

          <div className="not-prose flex scroll-pl-3 flex-row flex-nowrap justify-start gap-3 rounded-t-2xl bg-neutral-900 pt-3 hover:scroll-auto lg:justify-center">
            {data.allMdx.nodes.map((node) => (
              <Link
                to={`/blog/${node.slug}`}
                key={node.id}
                className="relative flex aspect-[3/4] w-[50%] min-w-[50%] snap-start flex-col gap-4 rounded-2xl border border-slate-800 bg-neutral-900">
                <GatsbyImage
                  image={getImage(node.frontmatter.heroImage)}
                  alt={node.frontmatter.heroImageAlt}
                  className="absolute top-0 right-0 bottom-0 left-0 h-full w-full rounded-2xl [&>*>*]:rounded-2xl"
                />
                <div className="absolute top-0 right-0 bottom-0 left-0 z-10 flex h-full w-full flex-col justify-end gap-2 rounded-2xl bg-gradient-to-t from-slate-900/75 to-slate-900/25 p-4">
                  <h1 className="text-xl font-bold text-gray-200 drop-shadow-[0_0_1px_rgba(0,0,0,0.25)] sm:text-2xl">
                    {node.frontmatter.title}
                  </h1>
                  <p className="text-sm drop-shadow-[0_0_1px_rgba(0,0,0,0.25)]">
                    {node.frontmatter.datePublished}
                  </p>
                </div>
              </Link>
            ))}
          </div>
          <Link
            to="/blog"
            className="not-prose mb-12 flex rounded-b-2xl bg-neutral-900 p-3 no-underline transition-all">
            All Posts →
          </Link>
        </main>
      </div>
      {/* body end */}
    </Layout>
  );
};

export const query = graphql`
  query {
    allMdx(
      limit: 3
      sort: { fields: frontmatter___datePublished, order: DESC }
    ) {
      nodes {
        frontmatter {
          title
          datePublished(formatString: "MMMM D, YYYY")
          heroImage {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        id
        slug
      }
    }
  }
`;

export default IndexPage;

export const Head = () => <Seo title="404: Not Found" />;
